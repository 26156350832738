
import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="about">
      <h2>About Us</h2>
      <p>Learn more about our company and our mission.</p>
    </section>
  );
}

export default About;
