import React, { useState } from 'react';
import './ChatBox.css';
import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg'; // Adjust the path based on your file structure

function ChatBox() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim() !== '') {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setInput('');
      setTimeout(() => {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Thank you for your message. We will get back to you soon!', sender: 'bot' }
        ]);
      }, 1000);
    }
  };

  return (
    <div className={`chat-container ${isOpen ? 'open' : ''}`}>
      <div className="chat-toggle" onClick={() => setIsOpen(!isOpen)}>
        <ChatIcon />
      </div>
      {isOpen && (
        <div className="chat-box">
          <div className="chat-header">Chat with Us</div>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBox;
