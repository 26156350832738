import React from 'react';
import './Services.css';

function Services() {
  return (
    <section id="services" className="services">
      <div className="service-overview">
        <h2>Our Services</h2>
        <p>
          Digital Data Dynamix offers a wide range of services to meet the needs of businesses in the digital age. Our expertise spans multiple areas, providing comprehensive solutions for our clients.
        </p>
      </div>
      <div className="service-categories">
        <h3>Service Categories</h3>
        <ul>
          <li>Data Analytics</li>
          <li>Cloud Solutions</li>
          <li>AI & Machine Learning</li>
          <li>Custom Software Development</li>
        </ul>
      </div>
      <div className="detailed-services">
        <h3>Data Analytics</h3>
        <p>Transform your data into actionable insights with our advanced analytics solutions.</p>
        <h3>Cloud Solutions</h3>
        <p>Leverage the power of the cloud to scale your business and improve efficiency.</p>
        <h3>AI & Machine Learning</h3>
        <p>Integrate AI and machine learning into your operations for smarter decision-making.</p>
        <h3>Custom Software Development</h3>
        <p>Get tailor-made software solutions designed to meet your specific business needs.</p>
      </div>
    </section>
  );
}

export default Services;
